<template>
  <div class="border border-gray-400 rounded-3xl p-4">
    <div
      class="font-bold text-xl border-b-2 pb-4 sm:flex sm:flex-row sm:justify-between"
    >
      <span class="ml-4"> Cross Selling Report </span>
      <div class="flex flex-row justify-start mt-4 sm:mt-0 gap-2">
        <router-link
          type="button"
          :to="{ name: 'CrossSellingHold' }"
          class="w-28 px-2 py-1 rounded-t-lg text-base text-center"
          :class="{
            'bg-primary text-white': isActive('CrossSellingHold'),
            'bg-gray-300': !isActive('CrossSellingHold'),
          }"
        >
          Hold
        </router-link>
        <router-link
          type="button"
          :to="{ name: 'CrossSellingRelease' }"
          class="w-28 px-2 py-1 rounded-t-lg text-base text-center"
          :class="{
            'bg-primary text-white': isActive('CrossSellingRelease'),
            'bg-gray-300': !isActive('CrossSellingRelease'),
          }"
        >
          Release
        </router-link>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "CrossSelling",

  methods: {
    isActive(name) {
      return this.$route.matched[2].name == name;
    },
  },
};
</script>
