var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border border-gray-400 rounded-3xl p-4"},[_c('div',{staticClass:"font-bold text-xl border-b-2 pb-4 sm:flex sm:flex-row sm:justify-between"},[_c('span',{staticClass:"ml-4"},[_vm._v(" Cross Selling Report ")]),_c('div',{staticClass:"flex flex-row justify-start mt-4 sm:mt-0 gap-2"},[_c('router-link',{staticClass:"w-28 px-2 py-1 rounded-t-lg text-base text-center",class:{
          'bg-primary text-white': _vm.isActive('CrossSellingHold'),
          'bg-gray-300': !_vm.isActive('CrossSellingHold'),
        },attrs:{"type":"button","to":{ name: 'CrossSellingHold' }}},[_vm._v(" Hold ")]),_c('router-link',{staticClass:"w-28 px-2 py-1 rounded-t-lg text-base text-center",class:{
          'bg-primary text-white': _vm.isActive('CrossSellingRelease'),
          'bg-gray-300': !_vm.isActive('CrossSellingRelease'),
        },attrs:{"type":"button","to":{ name: 'CrossSellingRelease' }}},[_vm._v(" Release ")])],1)]),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }